/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import * as PropTypes from 'prop-types'
import Tags from '../components/Tags'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO/SEO'
import Content, { HTMLContent } from '../components/Content'

const PrivacyPageTemplate = ({ content, contentComponent, tags, langKey }) => {
    const PageContent = contentComponent || Content
    return (
        <div className="container">
            <section className="section">
                <PageContent className="container content" content={content} />
                <Tags tags={tags} langKey={langKey} />
            </section>
        </div>
    )
}

PrivacyPageTemplate.propTypes = {
    content: PropTypes.string,
    contentComponent: PropTypes.func,
    tags: PropTypes.array,
    langKey: PropTypes.string,
}

class PrivacyPage extends React.Component {
    render() {
        var dataMarkdown = []
        if (this.props.data !== null) {
            dataMarkdown = this.props.data.markdownRemark
        }
        const jsonData = this.props.data.allArticlesJson.edges[0].node.articles
        const { frontmatter } = dataMarkdown
        const langKey = frontmatter.lang
        const tags = frontmatter.tags
        return (
            <Layout
                className="container"
                data={this.props.data}
                jsonData={jsonData}
                location={this.props.location}
            >
                <SEO frontmatter={frontmatter} />
                <div>
                    <PrivacyPageTemplate
                        contentComponent={HTMLContent}
                        content={dataMarkdown.html}
                        tags={tags}
                        langKey={langKey}
                    />
                </div>
            </Layout>
        )
    }
}

PrivacyPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object,
}

export default PrivacyPage

export const pageQuery = graphql`
    query PrivacyPageQuery($id: String!) {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                id
                title
                description
                tags
                lang
            }
            fields {
                slug
            }
        }
    }
`
